import { initializeApp } from "firebase/app";
import { httpsCallable, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import {
  getDatabase,
  ref,
  push,
  update,
  get,
  child,
  set,
  runTransaction,
} from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { serverTimestamp } from "firebase/database";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyBy6QK5itTxWrGglnAqmJ03Q2aLFkCkSRo",
  authDomain: "pelagic-force-416814.firebaseapp.com",
  projectId: "pelagic-force-416814",
  storageBucket: "pelagic-force-416814.appspot.com",
  messagingSenderId: "566537468809",
  appId: "1:566537468809:web:549d6c98d9744e965a7f58",
  measurementId: "G-HCE9SZKNL3",
  databaseURL:
    "https://pelagic-force-416814-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);
const promptsRef = ref(db, "prompts");

export const saveEmailToMailingList = (email) => {
  const mailingListRef = ref(db, "mailingList");
  return push(mailingListRef, email);
};

export const getCustomTokenFromServer = async (walletAddress) => {
  const functions = getFunctions(app);
  const getCustomToken = httpsCallable(functions, "generateCustomToken");
  const { data } = await getCustomToken({ walletAddress });
  const customToken = data.token;
  await signInWithCustomToken(getAuth(app), customToken);
};

export const getCurrentUser = () => {
  return getAuth(app).currentUser;
};

export const updateUserProfile = async (walletAddress, hasShared) => {
  const userId = getCurrentUser().uid;
  const userRef = await ref(db, `profiles/${userId}`);
  await update(userRef, {
    walletAddress,
    timestamp: serverTimestamp(),
    hasShared,
  });
};

export const promptExists = async (prompt) => {
  const promptSnap = await get(child(promptsRef, prompt));
  return promptSnap.exists();
};

export const addToNFTQueue = async (
  requester,
  mintFee,
  prompt,
  background,
  txSig,
) => {
  // Get a reference to the "queue_counter" document
  const queueCounterRef = ref(db, "queue_counter");

  try {
    const promptSnap = await get(child(promptsRef, prompt));
    if (promptSnap.exists()) {
      toast.error("Prompt exists!");
      return;
    }

    await runTransaction(queueCounterRef, (currentData) => {
      if (currentData === null) {
        return { counter: 0 };
      }

      // Increment the queue number
      const newCounter = currentData.counter + 1;

      // Create the object to be added to the queue
      const queueEntry = {
        requester,
        mintFee,
        prompt,
        background,
        status: "READY",
        transaction: txSig,
      };

      // Add the entry to the "nft_queue" collection using the new queue number as the ID
      const queueEntryRef = ref(db, `nft_queue/${newCounter.toString()}`);
      set(queueEntryRef, queueEntry);
      set(child(promptsRef, prompt), true);
      // Return the new counter value
      return { counter: newCounter };
    });
    toast.success(
      "Successfully added to the queue. Enzo will be drawing your artwork according to the work queue",
    );
  } catch (error) {
    console.error("Error adding entry to the NFT queue:", error);
    throw error;
  }
};

export { db, app };
