import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { ToastContainer } from "react-toastify";
import "@fonts/icomoon/icomoon.woff";
import "./firebase";
import { Buffer } from "buffer";
window.Buffer = Buffer;

const alchemyUrl =
  "https://solana-mainnet.g.alchemy.com/v2/WpEqwC7MBIFeuP8mEZmAw2NWq3Z9ue12";
const wallets = [new PhantomWalletAdapter()];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ConnectionProvider endpoint={alchemyUrl}>
      <WalletProvider wallets={wallets} autoConnect>
        <ToastContainer theme="dark" autoClose={3000} />
        <App />
      </WalletProvider>
    </ConnectionProvider>
  </BrowserRouter>,
);
